import { useLayoutEffect } from 'react'

export default function useLockBodyScroll() {
  useLayoutEffect(() => {
    // Get original value of body overflow & tl edges
    const elem = document.getElementsByClassName('tl-edges')[0]
    const originalStyle = window.getComputedStyle(document.body)
    const tlOriginalStyle = window.getComputedStyle(elem, null)
    // cleanup function
    function resetStyles() {
      document.body.style = originalStyle
      elem.style = tlOriginalStyle
    }
    // Prevent scrolling on mount
    document.body.style.overflowY = 'hidden'
    elem.style.overflowY = 'hidden'
    // Re-enable scrolling when component unmounts
    return () => resetStyles()
  }, []) // Empty array ensures effect is only run on mount and unmount
}
