/* eslint-disable import/no-cycle */
import React from 'react'
import { Flex, Box } from 'rebass'
import Img from 'gatsby-image'
import { rhythm } from '@utils/typography'
import { ProjectH1 } from '@components/home/projectheading'
import socialChannels from '@data/socialmedia'
import { motion } from 'framer-motion'
import { useTheme, useAppContext } from '@context/context'
import styled from 'styled-components'

const transition = {
  duration: 0.4,
  ease: [0.6, 0.01, -0.05, 0.9],
  delay: 0.1,
}
const Contact = ({ project, viewportHeight, MotionImage }) => {
  const theme = useTheme()

  const { contactViewSwitchControls } = useAppContext()
  return (
    <>
      <ContactMotionBox
        initial={false}
        animate={contactViewSwitchControls}
        transition={transition}
        flexWrap="wrap"
        alignItems="center"
        flexDirection="column"
        justifyContent="space-evenly"
        height="75%"
        maxHeight="540px"
        pl={[`${rhythm(1)},${rhythm(1)},${rhythm(1)},${rhythm(1)}`]}
      >
        <Box
          width={[1, '80%', 1, 1]}
          px={['42px', '64px', '64px', '128px']}
          maxWidth="800px"
        >
          <ProjectH1 mb={rhythm(1)}>About Me</ProjectH1>
          <p>
            I&apos;m a freelance motion designer and animator living in
            Brooklyn, NY, with over a decade of experience in all things motion
            design and animation.
          </p>
          <p>
            My experiences have shown me the importance of developing projects
            that motivate, inspire and engage audiences. If you&apos;ve got a
            story you want to share with the world, I&apos;d love to hear from
            you.
          </p>
          <p>
            If I&apos;m not designing or dropping keyframes, I&apos;m usually
            cooking, producing music, or fawning over my two pets: a one-eyed
            cat named Dylan and my rescue pup Eevee.
          </p>
        </Box>

        <Box
          width={[1, '80%', 1, 1]}
          px={['42px', '64px', '64px', '128px']}
          maxWidth="800px"
          className="contactBox"
        >
          <h2>
            Email:{' '}
            <span>
              <a rel="noopener noreferrer" href="mailto:info@ahoagmedia.com">
                info@ahoagmedia.com
              </a>
            </span>
          </h2>

          <h2>
            Phone:{' '}
            <span>
              <a rel="noopener noreferrer" href="tel:910.616.2727">
                910.616.2727
              </a>
            </span>
          </h2>
        </Box>

        <Box>
          <Flex alignItems="center">
            {socialChannels.map(socialChannel => (
              <Box
                key={socialChannel.key}
                px={`${rhythm(1 / 4)}`}
                m="0 auto"
                display="flex"
              >
                <motion.div
                  initial={{ opacity: 0.75 }}
                  transition={transition}
                  whileHover={{
                    opacity: 1,
                    scale: 1.05,
                    transition: {
                      duration: 0.2,
                    },
                  }}
                  key={socialChannel.key}
                >
                  <a
                    href={socialChannel.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={socialChannel.name}
                    style={{ color: theme.secondary }}
                  >
                    {socialChannel.icon}
                  </a>
                </motion.div>
              </Box>
            ))}
          </Flex>
        </Box>
      </ContactMotionBox>
      <Box
        width={1}
        sx={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          height: ` ${viewportHeight}`,
          width: '100%',
          zIndex: -1,
          opacity: 0.1,
          overflow: 'hidden',
        }}
      >
        <MotionImage>
          <Img
            alt="Andrew Hoag - Motion Designer | Animator | 3D Generalist"
            title="Andrew Hoag - Motion Designer | Animator | 3D Generalist"
            style={{
              width: '100%',
              height: '100%',
              zIndex: -1,
              opacity: 1,
            }}
            fluid={project.node.frontmatter.featureimage.childImageSharp.fluid}
          />
        </MotionImage>
      </Box>
    </>
  )
}
const ContactMotionBox = styled(motion.custom(Flex))``
export default Contact
