import styled, { ThemeContext } from 'styled-components'
import React, { useContext } from 'react'
import { motion } from 'framer-motion'
import { Flex, Box } from 'rebass'
import { transparentize } from 'polished'
import { rhythm } from '@utils/typography'
import { useTransitionState } from 'gatsby-plugin-transition-link/hooks'
import ProjectHeading from '@components/home/projectheading'

const Sidecard = ({ children, project, dataAnchor, location }) => {
  const themeContext = useContext(ThemeContext)
  const sidecardColor = `${transparentize(0.1, themeContext.primary)}`
  const state = useTransitionState()
  const transition = { duration: 1, ease: [0.6, 0.01, -0.05, 0.9] }

  return (
    <SidecardFlex
      bg={sidecardColor}
      key={project.node.id}
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      initial={false}
      animate={
        ['entering', 'entered'].includes(state.transitionStatus)
          ? { x: 0, opacity: 1 }
          : { x: `${-100}%`, opacity: 0 }
      }
      transition={transition}
    >
      <Box
        width={1}
        pl={[`${rhythm(1)}`, `${rhythm(1)}`, '55px']}
        p4={['12px', '22.5px']}
      >
        <ProjectHeading
          project={project}
          dataAnchor={dataAnchor}
          location={location}
        />
        {children}
      </Box>
    </SidecardFlex>
  )
}

export default Sidecard

const SidecardFlex = styled(motion.custom(Flex))`
  position: absolute;
  left: 0;
  top: 0;
  width: 75%;
  height: 100%;
  min-width: 275px;
`
