import { graphql, useStaticQuery } from 'gatsby'

export const useAllProjectsQuery = () => {
  const data = useStaticQuery(graphql`
    {
      allAnchors: allMdx(
        sort: { order: DESC, fields: frontmatter___date }
        filter: { frontmatter: { featured: { eq: true } } }
      ) {
        edges {
          node {
            fields {
              slug
            }
            id
            fileAbsolutePath
            frontmatter {
              client
              featureimage {
                childImageSharp {
                  fluid(maxWidth: 800, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
              widescreen
              title
              tags
            }
          }
        }
      }
      projectsSEO: file(absolutePath: { regex: "/DefaultImage/" }) {
        childImageSharp {
          resize(width: 1024, height: 1024, cropFocus: CENTER) {
            src
            width
            height
          }
        }
      }
      works: allMdx(
        sort: { order: DESC, fields: frontmatter___date }
        filter: {
          frontmatter: { client: { ne: "Contact" }, title: { ne: "2020 Reel" } }
        }
      ) {
        group(field: frontmatter___tags) {
          tag: fieldValue
          totalCount
        }
        edges {
          node {
            fields {
              slug
            }
            id
            fileAbsolutePath
            frontmatter {
              client
              featureimage {
                childImageSharp {
                  fluid(maxWidth: 800, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              title
              tags
            }
          }
        }
      }
      contact: allMdx(
        filter: { frontmatter: { client: { eq: "About + Contact" } } }
      ) {
        edges {
          node {
            id

            frontmatter {
              client
              featureimage {
                childImageSharp {
                  fluid(maxWidth: 800, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              title
            }
          }
        }
      }
    }
  `)
  return data
}
