import React, { useEffect } from 'react'
import Sidecard from '@elements/sidecard'
import { useAllProjectsQuery } from '@queries/ALL_PROJECTS_QUERY'
import { useAppContext } from '@context/context'
import { motion } from 'framer-motion'
import ReactFullpage from '@fullpage/react-fullpage'
import styled from 'styled-components'
import { useTransitionState } from 'gatsby-plugin-transition-link/hooks'
import Img from 'gatsby-image'
import Contact from '@components/contact'
import PropTypes from 'prop-types'
import { useWindowSize } from 'react-use'

export const MotionImage = ({ children, dataAnchor, viewportHeight }) => {
  const state = useTransitionState()

  const { enteringVideoHeight } = useAppContext()

  const trigger = ['exiting', 'exited'].includes(state.transitionStatus)

  return (
    <MotionImg
      initial={false}
      animate={{
        height: trigger ? `${enteringVideoHeight}px` : `${100}vh`,
      }}
      transition={{
        duration: 0.6,
        ease: [0.6, 0.01, -0.05, 0.9],
        delay: 0.25,
      }}
      className={dataAnchor}
      viewportHeight={viewportHeight}
    >
      {children}
    </MotionImg>
  )
}

const FullPageWrapper = ({ location }) => {
  const data = useAllProjectsQuery()
  const { allAnchors } = data
  const { edges: anchorMap } = allAnchors
  const { height } = useWindowSize()

  const anchors = anchorMap.map(project =>
    project.node.frontmatter.title.replace(/ /g, '')
  )

  const { isNavOpen, setActiveFPSlide } = useAppContext()

  return (
    <ReactFullpage
      licenseKey="D042E389-12434B14-ADDE7EA9-BFDF1CC5"
      touchSensitivity={10}
      navigation
      navigationPosition="right"
      navigationTooltips={anchors}
      anchors={anchors}
      afterLoad={() => {}}
      animateAnchor={false}
      render={({ state, fullpageApi }) => {
        const PROJECTS = anchorMap.map((project, index) => {
          const dataAnchorSpaced = project.node.frontmatter.client
          const dataAnchor = dataAnchorSpaced.replace(/ /g, '')
          if (state.initialized) {
            const { anchor } = fullpageApi.getActiveSection()
            setActiveFPSlide(anchor)
          }
          return (
            <ProjectSidecards
              viewportHeight={`${height}px`}
              key={project.node.id}
              project={project}
              index={index}
              fullpageApi={fullpageApi}
              fpAPIstate={state}
              dataAnchor={dataAnchor}
              isNavOpen={isNavOpen}
              location={location}
            />
          )
        })
        return <>{PROJECTS}</>
      }}
    />
  )
}

const ProjectSidecards = ({
  fullpageApi,
  dataAnchor,
  project,
  index,
  isNavOpen,
  fpAPIstate,
  viewportHeight,
  location,
}) => {
  useEffect(() => {
    if (isNavOpen) {
      fullpageApi.setAllowScrolling(false)
    } else if (fpAPIstate.initialized === true && isNavOpen === false) {
      fullpageApi.setAllowScrolling(true)
      if (location.hash === '#About') {
        fullpageApi.silentMoveTo('About')
      }
      if (location.hash === '#Contact') {
        fullpageApi.silentMoveTo('Contact')
      }
      if (location.hash === '') {
        fullpageApi.silentMoveTo('2020Reel')
      }
    }
  }, [location, fullpageApi, fpAPIstate, isNavOpen])
  const state = useTransitionState()

  let bgSection
  // Contact Component
  if (dataAnchor === 'Contact') {
    bgSection = (
      <>
        <Contact
          key="Contact"
          location={location}
          fpAPIstate={fpAPIstate}
          index={index}
          fullpageApi={fullpageApi}
          state={state}
          dataAnchor={dataAnchor}
          project={project}
          viewportHeight={viewportHeight}
          MotionImage={MotionImage}
        />
      </>
    )
    // Reel Component
  } else if (project.node.frontmatter.client === 'Andrew Hoag') {
    bgSection = (
      <>
        <Sidecard location={location} project={project} />
        <Img
          alt={`${`Featured image from ${project.node.frontmatter.client}'s ${project.node.frontmatter.title} by Andrew Hoag`}`}
          title={`${`Featured image from ${project.node.frontmatter.client}'s ${project.node.frontmatter.title} by Andrew Hoag`}`}
          style={{
            position: 'absolute',
            top: 0,
            left: '-100%',
            right: 0,
            height: `${viewportHeight}`,
            zIndex: -1,
          }}
          fluid={project.node.frontmatter.featureimage.childImageSharp.fluid}
        />
      </>
    )
    // Projects Component
  } else {
    bgSection = (
      <>
        <Sidecard
          location={location}
          project={project}
          dataAnchor={dataAnchor}
        />
        <MotionImage useRelative>
          <Img
            alt={`${`Featured image from ${project.node.frontmatter.client}'s ${project.node.frontmatter.title} by Andrew Hoag`}`}
            title={`${`Featured image from ${project.node.frontmatter.client}'s ${project.node.frontmatter.title} by Andrew Hoag`}`}
            fluid={project.node.frontmatter.featureimage.childImageSharp.fluid}
            style={{ height: '100%' }}
          />
        </MotionImage>
      </>
    )
  }
  return (
    <div
      key={project.node.id}
      className="section homepageSidecard"
      data-tooltip={project.node.frontmatter.client}
      data-anchor={dataAnchor}
    >
      {bgSection}
    </div>
  )
}

export const MotionImg = styled(motion.div)`
  position: ${props => (props.useRelative ? 'relative' : 'absolute')};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  width: 100%;
`

FullPageWrapper.propTypes = {
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      edges: PropTypes.shape({
        node: PropTypes.shape({
          fields: PropTypes.shape({
            slug: PropTypes.string,
          }),
        }),
      }),
      id: PropTypes.string,
      fileAbsolutePath: PropTypes.string,
      frontmatter: PropTypes.shape({
        client: PropTypes.string,
        featureimage: PropTypes.shape({
          childImageSharp: PropTypes.shape({
            fluid: PropTypes.shape({}),
          }),
        }),
        title: PropTypes.string,
        tags: PropTypes.arrayOf(PropTypes.string),
      }),
    }),
  }).isRequired,
}
export default FullPageWrapper
