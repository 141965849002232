import FullPageWrapper from '@components/home/projectsidecards'
import React from 'react'
import TransitionContext from '@context/transitioncontext'
import useLockBodyScroll from '@hooks/useLockBodyScroll'
import SEO from '@components/seo'
import { useTheme } from '@context/context'
import { useSiteQuery } from '@queries/SITE_QUERY'

const Home = props => {
  useLockBodyScroll()
  const { location } = props
  const theme = useTheme()
  const data = useSiteQuery()
  const { file } = data
  const { defaultSocialCard } = file

  const fallbackSocialImage = defaultSocialCard.resize

  return (
    <TransitionContext location={location}>
      <SEO
        title="Home"
        description="Andrew Hoag is a motion designer, animator, and 3D generalist living in Brooklyn, NY. Complete with over a decade of experience in all things motion and animation."
        pathname={location.pathname}
        image={fallbackSocialImage}
      />
      <FullPageWrapper siteMeta={data} theme={theme} location={location} />
    </TransitionContext>
  )
}

export default Home
